<template>
  <div>
    <v-row>
      <v-col>
        <h2>Step 3:</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert border="top" colored-border type="info" elevation="2">
          Before scheduling please ensure you schedule at the end of your
          off-going shift or schedule on an off-day to allow time for potential
          post vaccination recovery.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Select a day for your appointment by clicking on the day:</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <date-picker-widget
          :key="datePickerKey"
          v-model="availableDays"
          :disable-back="dateStack.length == 0"
          :disable-forward="disableForward"
          :loading="loadingSummaries"
          @daySelected="daySelected"
          @backButton="onBackButton"
          @forwardButton="onForwardButton"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Select an appointment by clicking on it below:</p>
      </v-col>
    </v-row>
    <v-row v-if="availableAppointments.length === 0 && loadingAppointments">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row
      v-if="
        availableAppointments.length === 0 &&
        noMoreAppointmentsFlag &&
        !loadingAppointments
      "
    >
      <v-col>
        <span class="error--text"
          >No appointments are available, please try changing the location or
          selecting another day</span
        >
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="6"
        v-for="(item, index) in availableAppointments"
        :key="index"
      >
        <v-card
          :class="selectedAppointment === item ? 'active ma-4' : 'ma-4'"
          @click="handleSelect(item)"
          style="padding: 0px; margin: 0px"
        >
          <v-card-title>
            {{ item.appointmentLabel }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <span
                  v-html="getLocationAddressString(selectedLocation)"
                ></span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" ms="12" md="8">
                <manufacturer-chip
                  :manufacturerId="selectedLocation.vaccineManufacturerId"
                  :manufacturerName="selectedLocation.vaccineManufacturerName"
                  v-if="
                    selectedLocation &&
                    selectedLocation.vaccineManufacturerId > 0
                  "
                ></manufacturer-chip>
                <dose-type-chip
                  :doseType="selectedLocation.vaccineDoseType"
                  v-if="selectedLocation.vaccineDoseType"
                ></dose-type-chip>
                <v-chip color="primary" dark class="ma-2" small>
                  <span class="subtitle-2">Scheduled {{ item.scheduled }}</span>
                </v-chip>
                <v-chip dark color="primary" class="ma-2" small>
                  <span class="subtitle-2">Capacity {{ item.capacity }}</span>
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="selectedDay">
        <v-btn
          x-large
          color="primary"
          style="width: 100%"
          v-if="!noMoreAppointmentsFlag"
          @click="getMoreAppointments"
          :loading="availableAppointments.length > 0 && loadingAppointments"
          :disabled="loadingAppointments"
          >Show More Appointments</v-btn
        >
        <v-btn
          x-large
          style="width: 100%"
          v-else
          disabled
          >No More Appointments</v-btn
        >
      </v-col>
      <v-col cols="12">
        <v-btn x-large style="width: 100%" @click="goBack">Go Back</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    selectedLocation: {
      type: Object,
      default: {},
      required: true,
    },
    eligibleDate: {
      type: String,
      default: "",
      required: true,
    },
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  components: {
    manufacturerChip: () =>
      import("@/module/vaccineManufacturer/components/manufacturerChip"),
    doseTypeChip: () =>
      import("@/module/vaccineManufacturer/components/doseTypeChip"),
    datePickerWidget: () =>
      import("@/module/vaccineScheduling/signup/components/datePickerWidget"),
  },
  watch: {
    selectedDay: function (old, newVal) {
      this.appointmentPage = 0;
      this.noMoreAppointmentsFlag = false;
      this.availableAppointments = [];
      if (this.selectedDay) {
        this.getAvailableAppointments();
      }
    },
  },
  computed: {
    currentDate: {
      get: function () {
        return moment().format("L");
      },
    },
  },
  data() {
    return {
      datePickerKey: 1,
      dateStack: [],
      currentSummaryStartDate: null,
      disableForward: true,
      maxNumOfResults: 6,
      loadingSummaries: false,
      loadingAppointments: false,
      appointmentDaySelectMenu: false,
      numAppointmentResults: 5,
      appointmentPage: 0,
      noMoreAppointmentsFlag: false,
      selectedDay: null,
      availableAppointments: [],
      selectedAppointment: {},
      required: [(v) => !!v || "Required Field"],
      availableDays: [],
    };
  },
  methods: {
    ...mapActions("vaccineLocations", [
      "getAppointmentsByPage",
      "getAvailableDays",
    ]),
    getAppointmentSummaries(startDate) {
      this.loadingSummaries = true;
      this.getAvailableDays({
        locationId: this.selectedLocation.id,
        startDateTime: startDate,
        user34: this.user.user34,
        maxNumOfResults: this.maxNumOfResults,
      }).then((response) => {
        if (response.data.length > 5) {
          this.disableForward = false;
          this.availableDays = response.data.slice(0, 5);
        } else {
          this.disableForward = true;
          this.availableDays = response.data;
        }
      }).finally(() => this.loadingSummaries = false);
    },
    onBackButton() {
      this.currentSummaryStartDate = this.dateStack.pop()
      this.getAppointmentSummaries(this.currentSummaryStartDate)
    },
    onForwardButton() {
      this.dateStack.push(this.currentSummaryStartDate)
      this.currentSummaryStartDate = moment(this.availableDays[this.availableDays.length - 1].day)
                                    .add(1, "days")
                                    .toISOString(true)
      this.getAppointmentSummaries(this.currentSummaryStartDate)
    },
    daySelected: function (day) {
      this.selectedDay = day;
    },
    convertToLocal: function (date) {
      return moment.utc(date).local();
    },
    getMoreAppointments: function () {
      this.appointmentPage++;
      this.getAvailableAppointments();
    },
    getLocationAddressString: function (item) {
      return (
        item.address +
        "<br/>" +
        (item.address2 ? item.address2 + "<br/>" : "") +
        item.city +
        ", " +
        item.state +
        " " +
        item.zip
      );
    },
    getAvailableAppointments: function () {
      if (this.selectedLocation.id && this.selectedDay) {
        this.loadingAppointments = true;
        let startDateTime = moment(this.selectedDay)
          .startOf("day")
          .toISOString(true);
        let endDateTime = moment(this.selectedDay)
          .endOf("day")
          .toISOString(true);
        if (moment(new Date(this.selectedDay)).isSame(moment(), "day")) {
          startDateTime = moment().toISOString(true);
        }
        this.getAppointmentsByPage({
          location: this.selectedLocation.id,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          numResults: this.numAppointmentResults,
          pageIndex: this.appointmentPage,
        }).then((response) => {
          let appointments = response.data;
          if (appointments.length === 0) {
            this.noMoreAppointmentsFlag = true;
            this.loadingAppointments = false;
          } else {
            appointments.forEach((item, i) => {
              item.appointmentLabel =
                this.convertToLocal(item.datetime_start).format(
                  "MMM Do h:mm a"
                ) +
                " - " +
                this.convertToLocal(item.datetime_end).format("MMM Do h:mm a");
            });
            this.availableAppointments.push(...appointments);
            this.loadingAppointments = false;
          }
        });
      } else {
        this.availableAppointments = [];
        this.loadingAppointments = false;
      }
    },
    handleSelect: function (item) {
      this.selectedAppointment = item;
      this.$emit("appointmentSelected", this.selectedAppointment);
    },
    goBack: function () {
      this.$emit("goBack");
    },
  },
  mounted() {
    this.datePickerKey++;
    this.currentSummaryStartDate = moment().toISOString(true)
    this.getAppointmentSummaries(this.currentSummaryStartDate)
  },
};
</script>
