var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Step 3:")])])],1),_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"border":"top","colored-border":"","type":"info","elevation":"2"}},[_vm._v(" Before scheduling please ensure you schedule at the end of your off-going shift or schedule on an off-day to allow time for potential post vaccination recovery. ")])],1)],1),_c('v-row',[_c('v-col',[_c('p',[_vm._v("Select a day for your appointment by clicking on the day:")])])],1),_c('v-row',[_c('v-col',[_c('date-picker-widget',{key:_vm.datePickerKey,attrs:{"disable-back":_vm.dateStack.length == 0,"disable-forward":_vm.disableForward,"loading":_vm.loadingSummaries},on:{"daySelected":_vm.daySelected,"backButton":_vm.onBackButton,"forwardButton":_vm.onForwardButton},model:{value:(_vm.availableDays),callback:function ($$v) {_vm.availableDays=$$v},expression:"availableDays"}})],1)],1),_c('v-row',[_c('v-col',[_c('p',[_vm._v("Select an appointment by clicking on it below:")])])],1),(_vm.availableAppointments.length === 0 && _vm.loadingAppointments)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"size":100,"color":"primary","indeterminate":""}})],1)],1):_vm._e(),(
      _vm.availableAppointments.length === 0 &&
      _vm.noMoreAppointmentsFlag &&
      !_vm.loadingAppointments
    )?_c('v-row',[_c('v-col',[_c('span',{staticClass:"error--text"},[_vm._v("No appointments are available, please try changing the location or selecting another day")])])],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.availableAppointments),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"12","md":"12","lg":"6"}},[_c('v-card',{class:_vm.selectedAppointment === item ? 'active ma-4' : 'ma-4',staticStyle:{"padding":"0px","margin":"0px"},on:{"click":function($event){return _vm.handleSelect(item)}}},[_c('v-card-title',[_vm._v(" "+_vm._s(item.appointmentLabel)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getLocationAddressString(_vm.selectedLocation))}})])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","ms":"12","md":"8"}},[(
                  _vm.selectedLocation &&
                  _vm.selectedLocation.vaccineManufacturerId > 0
                )?_c('manufacturer-chip',{attrs:{"manufacturerId":_vm.selectedLocation.vaccineManufacturerId,"manufacturerName":_vm.selectedLocation.vaccineManufacturerName}}):_vm._e(),(_vm.selectedLocation.vaccineDoseType)?_c('dose-type-chip',{attrs:{"doseType":_vm.selectedLocation.vaccineDoseType}}):_vm._e(),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","dark":"","small":""}},[_c('span',{staticClass:"subtitle-2"},[_vm._v("Scheduled "+_vm._s(item.scheduled))])]),_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":"primary","small":""}},[_c('span',{staticClass:"subtitle-2"},[_vm._v("Capacity "+_vm._s(item.capacity))])])],1)],1)],1)],1)],1)}),1),_c('v-row',[(_vm.selectedDay)?_c('v-col',{attrs:{"cols":"12"}},[(!_vm.noMoreAppointmentsFlag)?_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"x-large":"","color":"primary","loading":_vm.availableAppointments.length > 0 && _vm.loadingAppointments,"disabled":_vm.loadingAppointments},on:{"click":_vm.getMoreAppointments}},[_vm._v("Show More Appointments")]):_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"x-large":"","disabled":""}},[_vm._v("No More Appointments")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"x-large":""},on:{"click":_vm.goBack}},[_vm._v("Go Back")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }